import React from "react";
import Header from "../components/Header"
import Layout from "../layouts/layout"
import HomeBanner from "../components/Banner/HomeBanner"
import SEOPage from "../components/SEOPage"
import ContentHeadingPara from "../components/ContentHeadingPara"
import Tool from '../components/Tool';
import "../ui-lib/styles/Main.scss"
import "../ui-lib/styles/toolsandbenefits.scss"

const DynosBlossoms = () => {

    const banner = {
        heading: "Nurturing Young Minds with Holistic Learning for Pre-Primary Excellence",
        subHeading: "Empowering Nursery, LKG, and UKG Students with Premium, Integrated Educational Solutions.",
        button: "Login",
        image: "/preprimaryclass.png"
    }

    const dynotypeHead = {
        heading: "Dynos Comprehensive Learning Solution for Pre-Primary",
        subHeading: "Immerse your young learners in an enriching, interactive educational environment with the Dynos Comprehensive Learning Solution. Specially designed for pre-primary students, our integrated solution fosters holistic learning by combining both traditional and digital methodologies. This comprehensive package includes premium learning materials tailored for Nursery, LKG, and UKG levels."
    }

    // Tools data
    const toolsData = [
        {
            icon: '📚',
            title: 'Print Books',
            description: 'High-quality, comprehensive educational books designed to facilitate a thorough understanding of key concepts.'
        },
        {
            icon: '💻',
            title: 'Dynos Learning Management System (LMS)',
            description: [
                'Streamline teaching with day-wise guided digital lesson plans.',
                'Includes a Pre-Primary workflow with a yearly plan divided into months, weeks, and days.',
                'Integrates activities based on themes from textbook topics and in-class assignments.'
            ]
        },
        {
            icon: '📈',
            title: 'Academic ERP',
            description: 'Monitor attendance and send notifications to parents about their child\'s performance and other pertinent information.'
        },
        {
            icon: '🎥',
            title: 'Topic-Wise Videos',
            description: 'Enhance understanding with immersive videos, including English and Hindi nursery rhymes.'
        },
        {
            icon: '📊',
            title: 'Topic-Wise PPTs',
            description: 'Support classroom teaching and enable an interactive learning experience with engaging presentations.'
        },
        {
            icon: '🎮',
            title: 'Educational Games',
            description: 'Fun and engaging games that make learning enjoyable and effective.'
        },
        {
            icon: '📝',
            title: 'Worksheets',
            description: 'Reinforce learning and encourage concept practice with extensive worksheets.'
        },
        {
            icon: '📱',
            title: 'Dynos Blossom App',
            description: 'A dedicated app for students, providing a learning platform right at their fingertips.'
        },
        {
            icon: '🧑‍🏫',
            title: 'In-Person Implementation Workshops',
            description: 'Facilitate smooth and effective implementation of our solutions through hands-on workshops.'
        },
        {
            icon: '🌐',
            title: 'Webinars for Teachers & Parents',
            description: 'Regular webinars to guide educators and parents in enhancing the child’s learning experience.'
        }
    ];

    // Benefits data
    const benefitsData = [
        {
            title: "Holistic Learning: ",
            description: 'Combines traditional and digital methodologies for a well-rounded educational experience.'
        },
        {
            title: "Interactive and Engaging: ",
            description: 'Uses multimedia resources to keep young learners engaged.'
        },
        {
            title: "Streamlined Teaching: ",
            description: 'Provides structured lesson plans to help teachers manage their curriculum efficiently.'
        },
        {
            title: "Parental Involvement: ",
            description: 'Keeps parents informed and involved in their childs learning journey.'
        }
    ];
    return (
        <Layout>
            <SEOPage
                title="Dynos | Product | Interactive Digital Content for Schools."
                description="Dynos empowers schools to create engaging digital classrooms through interactive content, integrated lesson plans, and a user-friendly learning management system."
                image="/homeMetaImage.png"
            />
            <section className="dynosBlossom-bg">
                <section className="main--wraper-dynosBlossom">
                    <Header headerClass="headercolor" />
                    <section className="main--container dynosBlossom-wrap">
                        {/* <ProductBanner heading={banner.heading} subHeading={banner.subHeading} button={banner.button} /> */}
                        <HomeBanner heading={banner.heading} subHeading={banner.subHeading} button={banner.button} image={banner.image} color="blue" />
                    </section>
                </section>
                <section className="main--container">
                    <section className="align--center mt-120 padding-tablet zIndex">
                        <ContentHeadingPara heading={dynotypeHead.heading} subHeading={dynotypeHead.subHeading} />
                    </section>
                </section>
                <section>
                    <>
                        <h3 style={{ textAlign: "center", padding: "0 0 30px 0", fontSize: "28px", color: "#223f99" }}>Offering</h3>
                        <div className="tools">
                            {toolsData.map((tool, index) => (
                                <Tool key={index} tool={tool} />
                            ))}
                        </div>
                    </>
                    <div className="benefits-info">
                        <h3 style={{ textAlign: "center", padding: "0 0 30px 0", fontSize: "28px", color: "#223f99" }}>Benefits</h3>
                        <div className="benefit--wrap">
                            {
                                benefitsData.map((benefitData, index) =>
                                    <Tool key={index} tool={benefitData} />
                                )
                            }
                        </div>
                    </div>
                </section>
            </section>
        </Layout>
    )
}
export default DynosBlossoms